import React from "react"
import Layout from "../components/layout"

const About = () => {
  return   <Layout>
    <section id="mainTitle" style={{background: 'white', color: 'black'}}>
      <div className="row">
        <div className="twelve columns">
          <br />
          <h1 className="responsive-headline"  style={{color: 'black'}}>LEGAL NOTICES</h1>
          <br />


          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Using this Website</h2>
          </div>
          <div>
            <p>
              THE USE OF THIS WEBSITE INDICATES THAT YOU HAVE READ, UNDERSTOOD AND ACCEPTED THESE TERMS OF USE (“TERMS”) AND YOU AGREE TO BE BOUND BY THEM AND TO COMPLY WITH ANY AND ALL APPLICABLE LAWS AND REGULATIONS. IF YOU DO NOT ACCEPT THESE TERMS, DO NOT USE THIS WEBSITE AND PLEASE LEAVE OUR WEBSITE IMMEDIATELY.</p>

            <p>
              This Website and all subpages are owned and operated by <a style={{color: "black"}} href="https://ineor.si/">Ineor d.o.o.</a> , who shall be referred to as <a style={{color: "black"}} href="https://ineor.si/">“Ineor”</a>, “Us” or “We”. This Website contains information on services, products and solutions offered by <a style={{color: "black"}} href="https://ineor.si/">Ineor</a>. Nothing contained within the pages of this Website shall be deemed to constitute any professional service or advice in any way.
            </p>

            <p>
              As owner of this Website, <a style={{color: "black"}} href="https://ineor.si/">Ineor</a> reserves the right to periodically complement and update these Terms at any time, in accordance with novelties and legislation by updating this posting (without announcement or notification of the users about this). You should visit this page from time to time to review the then-current Terms because they are binding on you. Certain provisions of these Terms may be superseded by expressly designated legal notices or terms located on particular pages at Website.
            </p>
            </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Disclaimer</h2>
          </div>
          <div>
            <p>
              Whilst we have taken every reasonable precaution and care in compiling this Website, it is provided on an "as is" basis. We do not make any representation or warranties of any kind (express or implied) with respect to the content or operation of this Website, including, but not limited to all documents and visual material (which may include photographs, graphs, illustrations, logos, maps, and other forms) posted on this Website, and any such representations and warranties are hereby excluded. 
            </p>
            <p>
              Your use of this Website and the materials contained in it is entirely at your own risk. We do not warrant that use of this Website is uninterrupted or error-free. You acknowledge that it is your responsibility to implement sufficient security and ensure that whatever you select for your use is free of all items of destructive nature (such as viruses, worms, etc.). Therefore, we do not accept any liability for loss or damage, whether direct or indirect, that you may suffer as a result of your use of this Website or your reliance upon the content of this Website, including, but not limited to computer service or system failure, access delays or interruption, data non-delivery or mis-delivery, computer viruses or other harmful components, breaches of security or unauthorized use of the system arising from "hacking" or otherwise.
            </p>
            <p>
              Except with respect to compulsory legal conditions we shall not be liable to you by reason of any representation, unless fraudulent, or any implied warranty, condition or other term, or any duty at common law or under any express terms contained herein, for any direct, indirect, special, incidental or consequential loss or damage (whether for loss of profit lost data or business interruption or otherwise), costs, expenses, or other claims for compensation whatsoever, whether caused by our negligence, our employees or agents, or otherwise, which arise out of or in connection with your use, inability to use, or the results of use of this Website, any websites linked to this Website, or the materials or information or services contained at any or all such websites, whether based on warranty, contract, tort or any other legal theory and whether or not advised of the possibility of such damages. If your use of the materials, information or services from this Website results in the need for servicing, repair or correction of equipment or data, you assume all costs thereof.
            </p>
            <p>
              Any typographical, clerical or other error or omission in any page posted on this Website shall be subject to correction or deletion (as appropriate) without any liability on our part.
            </p>
            <p>
              Any material, information or other communication you transmit or post to this Website, or to Us regarding this Website, will be considered non-confidential and non-proprietary (“User Submissions”). We are the sole owner of all User Submissions. We shall have no obligation of any kind with respect to User Submissions and shall be free to use User Submissions on a unrestrictive basis for any purpose (such as use, reproduction, modification, disclosure, (re)distribution, public display, etc.) and without an indication of the source. We may use the content of User Submissions including, but not limited to, ideas, concepts, knowledge, experience and techniques, for any purpose, including, but not limited to, development, production and marketing of products/services containing User Submissions, except in case of express indication by the visitor that the User Submissions be considered confidential and/or in case of express indication of the ban on the use of the User Submissions. We will not sell, share, or rent User Submissions to others in ways different from what is defined in these Terms.
            </p>
            <p>
              You are prohibited from posting or transmitting to or from this Website any unlawful, threatening, libelous, defamatory, obscene, pornographic, or other material that would violate any applicable law.
            </p>
            <p>
              We reserve the right to make changes to this Website at any time, as it deems appropriate without prior notification or obligation.
            </p>
            <p>
              We reserve the right to make changes to this Website at any time, as it deems appropriate without prior notification or obligation.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Copyrights and Trademarks</h2>
          </div>
          <div>
            <p>
              <a style={{color: "black"}} href="https://ineor.si/">© Ineor 2021. All rights reserved.</a>
              The entire content of this Website – all design, text, graphics, program code and the selection or arrangement of these are the copyright of Us or our licensors.
            </p>
            <p>
              Unless otherwise indicated, all trademarks, brand names, company names, logos and all slogans contained herein are our property or that of their respective owners. All reproduction, use and/or modification made without prior written permission of the party entitled and any other unauthorized use of any materials at this Website may violate copyright, trademark, and other laws.
            </p>
            <p>
              Unless otherwise stated in legal notices elsewhere on this Website, you may view, copy, print, download, store and use information contained in this Website only for your own personal, non-commercial use, for information purposes only and research or that of your firm or company, provided that you retain all copyright and other proprietary notices contained in the original content on any copies. You may not change, copy, multiply, distribute, republish, translate, retransmit, redistribute or otherwise make such information or pages available to any other party or on any website, on-line service or bulletin board of your own or of any other party or make the same available in hard copy or on any other media without our express prior written consent.
            </p>
            <p>
              Permission is granted only electronically to copy and/or print in hard copy portions of this Website for personal non-commercial purposes. Any other use of the materials on this Website (including reproduction for purposes other than those noted above and modification, distribution or re-publication) is strictly prohibited.
            </p>
            <p>
              Any software and other content that is made available for downloading, access or other use from this Website may be governed by its own license terms, conditions and notices. You may not download or install the software until you have read and accepted the terms of the respective software license agreement. If you breach any of these Terms, your authorization to use this Website automatically terminates and you must immediately destroy any downloaded or printed materials.
            </p>
            <p>
              As a user, as part of the authorized use of this Website’s content you shall be obliged to preserve all designations of copyright and other intellectual property rights as well as any other notifications and warnings. Unauthorized use or abuse of any of the registered trademarks or logos from this Website shall be prohibited. As a user, you are informed that in the case of violation of copyright or other intellectual property rights, <a style={{color: "black"}} href="https://ineor.si/">Ineor</a> can initiate civil and/or criminal proceedings against the violator.
            </p>
            <p>
              Except as expressly stated herein or any other webpage of the Website, nothing contained on the Site may be construed as granting you any right or license to use any of the intellectual property rights found on this Website, whether by estoppel, implication or otherwise.
            </p>
          </div>
          
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Third Party Links</h2>
          </div>
          <div>
            <p>
              Our Website may contain links to third party websites. We make no warranties and/or representations whatsoever about any other website which you may access through this one. Such links are provided only as a convenience to you because they may be of interest to you and this does not mean that we endorse, approve or accept any responsibility for the content of any website to which our Website links and We make no representations, warranties or other commitments about them, or any information, software or other products or materials found there, or any results that may be obtained from using them. If you decide to access any of the third party sites linked to this Website, you do this entirely at your own risk. Any other link to a third party will be covered by the privacy policy of that specific visited website. We are not responsible for the privacy policies or practices of third parties.</p>
            <p>
              No authority is given (implied or express) by us or the contributors to the Website to deep link to or to frame any of the content that appears on the Website or to use a representation of any of our names or logos, trademarks, or brand names contained on this Website. We reserve the right to prohibit links to our homepage.
            </p>
            
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Privacy, Personal data protection and Cookies</h2>
          </div>
          <div>
            <p>
              Your privacy and trust are important to us. We ensure that personal information collected by us is processed respectively and lawfully.</p>
            <p>
              To get comprehensive information about Privacy and Personal data protection with regard to this Website please check our Privacy Policy.
            </p>
            <p>
              For information about the use of cookies on this Website please check our Cookie policy.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Final Provisions</h2>
          </div>
          <div>
            <p>
              Should any provision of these Terms and Conditions be or become unlawful, invalid or non-enforceable, this shall not affect the other provisions hereof.
              To get comprehensive information about Privacy and Personal data protection with regard to this Website please check our Privacy Policy.
            </p>
            <p>
              <a style={{color: "black"}} href="https://ineor.si/">Ineor</a><br/>
              Last revision: 18.03.2019
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
};

export default About;